<template>
  <div class="company-container">
    <h2 class="mt-3">{{ $t('company-page.heading') }}</h2>
    <b-row class="my-3 d-flex justify-content-space-evenly">
      <b-col
        v-for="item in items"
        :key="item.route"
        cols="5"
        md="4"
        lg="3"
        class="my-2 d-flex justify-content-center text-center"
      >
        <router-link :to="`/company/${item.route}`">
          <div
            class="card-container text-center d-flex flex-column justify-content-center align-items-center"
          >
            <div
              class="w-100 card bg-primary justify-content-center text-white border-0"
            >
              <img :src="item.iconRoute" />
            </div>
            <p class="text-body mt-3">
              {{ $t(`company-page.${item.name}`) }}
              <span
                v-if="
                  item.badge && (displayMissingDataBadge || hasMissingDocuments)
                "
                v-b-tooltip.hover
                :title="
                  $t(
                    `account-missing-${
                      displayMissingDataBadge ? 'activation' : 'docs'
                    }`,
                  )
                "
              >
                <b-badge :variant="item.badge.variant" class="mx-2">
                  {{ $t(item.badge.text) }}
                </b-badge>
              </span>
            </p>
          </div>
        </router-link>
      </b-col>
      <b-col
        cols="5"
        md="4"
        lg="3"
        class="my-2 d-flex justify-content-center text-center"
      >
        <a href="https://wiki.getitdone.rocks/s/partner-faq" target="_blank">
          <div
            class="card-container text-center d-flex flex-column justify-content-center align-items-center"
          >
            <div
              class="w-100 card bg-primary justify-content-center align-items-center text-white border-0"
            >
              <BIconQuestion />
            </div>
            <p class="text-body mt-3">FAQ</p>
          </div>
        </a>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { AccountStatusEnum } from '@gid/models';
import { BIconQuestion } from 'bootstrap-vue';

export default {
  components: {
    BIconQuestion,
  },
  data() {
    return {
      items: [
        {
          route: 'qualifications',
          name: 'qualifications',
          iconRoute: '/icons/assignment_ind-black-48dp.svg',
        },
        {
          route: 'payment',
          name: 'payment.title',
          iconRoute: '/icons/account_balance_black_48dp.svg',
          badge: {
            display: false,
            variant: 'danger',
            text: 'company-page.payment.missing',
          },
        },
        {
          route: 'dashboard',
          name: 'dashboard',
          iconRoute: '/icons/assessment-white-48dp.svg',
        },
        {
          route: 'gid-badge',
          name: 'gid-badge',
          iconRoute: '/icons/wb_iridescent-white-48dp.svg',
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['user']),
    displayMissingDataBadge() {
      return ![
        AccountStatusEnum.ACTIVE,
        AccountStatusEnum.BEGINNER_ONBOARDING,
        AccountStatusEnum.REPEATED_ONBOARDING,
      ].includes(this.user?.account.account_status);
    },
    hasMissingDocuments() {
      return (
        this.$store.getters['companyDocs/missingDocumentErrors'].length > 0
      );
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@gid/vue-common/scss/_variables.scss';
@import '../../assets/scss/_variables.scss';

.card {
  height: 135px !important;
  border-radius: 17% !important;
  font-size: 8em;
}
.card:hover {
  background-color: lighten($primary, 10%) !important;
}

.card-container {
  width: 135px;
}
.company-container {
  min-height: 70vh;
}
</style>
