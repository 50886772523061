<template>
  <div v-if="order">
    <h2 class="mt-3">
      {{ order.opportunity && order.opportunity.name }}
    </h2>
    <h5 class="mt-2 mb-4">{{ order.job.name }}</h5>
    <order-summary :order="order" :short="true" />
    <b-row
      v-if="canCreateProposal"
      class="border-bottom py-3 py-lg-5 bg-light align-items-center"
    >
      <b-col cols="12" lg="3" class="text-muted">
        <h3 class="font-weight-light mb-3 mb-lg-0">
          {{ $t('order-details.actions') }}:
        </h3>
      </b-col>
      <b-col
        cols="12"
        lg="9"
        class="d-flex flex-column flex-sm-row align-items-stretch align-items-sm-center"
      >
        <b-button
          :to="{ name: 'create', params: { job_id } }"
          variant="primary"
          size="lg"
          class="text-uppercase px-5 mr-sm-2 mb-2 mb-sm-0 d-block d-sm-inline-block"
        >
          {{ $t('proposal.create') }}
        </b-button>
      </b-col>
    </b-row>
  </div>
  <not-found v-else-if="order === false" />
</template>

<script>
/* eslint-disable vue/no-unused-components */
// eslint can't detect the dynamic usage of the components

import axios from 'axios';
import { mapGetters } from 'vuex';

import NotFound from '@/views/NotFound.vue';
import Summary from './Summary.vue';

import { checkRouteAccess } from '@gid/vue-common/store/auth.module';
import { UserRoleEnum } from '@gid/models';

export default {
  components: {
    NotFound,
    'order-summary': Summary,
  },
  props: {
    job_id: {
      type: String,
      required: true,
    },
  },
  watch: {
    job_id() {
      this.loadData();
    },
  },
  data() {
    return {
      order: null,
    };
  },
  computed: {
    ...mapGetters(['locale', 'user', 'isAuthenticated']),
    canCreateProposal() {
      if (
        this.order.opportunity.can_create_proposal?.includes(
          UserRoleEnum.PARTNER,
        ) &&
        this.order.is_precheck
      ) {
        const resolved = this.$router.resolve({ name: 'create' });
        return (
          resolved &&
          checkRouteAccess({
            route: resolved.route,
            user: this.user,
            isAuthenticated: this.isAuthenticated,
          })
        );
      }
      return false;
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.order = null;
      axios
        .get(`/api/partner/orders/${this.job_id}`)
        .then((response) => {
          this.order = response.data;
          this.$store.commit(
            'title',
            this.$t('order-with-number', { num: this.order.job.name }),
          );
        })
        .catch((error) => {
          if (error.response && error.response.status == 404) {
            this.order = false;
          }
        });
    },
  },
};
</script>
